export const Testimonials = (props) => {
  return (
    <div id='testimonials'>
      <div className='container' style={{width:"90%"}}>
        <div className='section-title text-center'>
          <h2>Our Clients</h2>
        </div>
        <div className='row'  >
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-1' style={{marginBottom:"5%"}}>
                  {/* <div className='testimonial'> */}
                    {/* <div className='testimonial-image'> */}
                      {' '}
                      { d.short === false ? <img src={d.img} alt='' height='10%' width='100%'/> : <img src={d.img} alt='' height='10%' width='100%'/> }
                      {' '}
                    {/* </div> */}
                    {/* <div className='testimonial-content'>
                      <p>"{d.text}"</p>
                      <div className='testimonial-meta'> - {d.name} </div>
                    </div> */}
                  </div>
                // </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
